<template>
  <div id="order-view-actions-spec-awaiting-quotation">
    <OrderViewActionAccept v-if="!isQuoteOwner && quoteSelected.quote.paymentTerm !== 'on_contract'" btn-icon="CreditCardIcon" :btn-text="$tc('payment.title', 1)" />
    <div v-if="isQuoteOwner">
      <b-button
        id="order-view-action-service-scheduling"
        v-ripple.400
        v-scroll-to="'#order-view-services'"
        variant="success"
        class="mb-75"
        block
        :disabled="disabled || !quoteSelected.quote.lines.length"
      >
        <feather-icon icon="CheckIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.service_scheduling') }} </span>
      </b-button>
    </div>
    <div v-else>
      <b-button
        v-if="quoteSelected.checkout"
        id="order-view-actions-spec-awaiting-quotation-add-item"
        v-ripple.400
        class="mb-75"
        block
        :disabled="disabled"
        @click="redirectToCatalog"
      >
        <feather-icon icon="PlusIcon" size="20" class="align-middle" />
        <span class="ml-25 align-middle"> {{ $t('action.add_item') }} </span>
      </b-button>
      <OrderViewActionCancelAndEdit
        v-if="enableRecallCart()"
        :disabled="disabled"
        :btn-text="$t('order.cancel_and_recall_order')"
        btn-icon="RepeatIcon"
        :confirm-title="$t('order.cancel_and_recall_order')"
        :confirm-question="$t('order.order.edit_question')"
        :confirm-text="$t('order.order.edit_text_fo')"
      />
    </div>
    <OrderViewActionCancelOrRefuse
      v-if="$can('TRIP_ACCOUNTING_DELETE') && enableCancel()"
      :disabled="disabled"
      btn-icon="XCircleIcon"
      :btn-text="$t('order.cancel_order')"
      :refuse-title="$t('order.cancel_order')"
    />
    <OrderViewActionsSpecPaid
      v-if="quoteSelected.paymentIntent && quoteSelected.paymentIntent.status === 'succeeded'"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OrderViewActionCancelOrRefuse from '@/views/order/OrderViewActionCancelOrRefuse.vue'

export default {
  name: 'OrderViewActionsSpecAwaitingServicesCompleted',

  components: {
    OrderViewActionAccept: () => import('@/views/order/OrderViewActionAccept.vue'),
    OrderViewActionCancelAndEdit: () => import('@/views/order/OrderViewActionCancelAndEdit.vue'),
    OrderViewActionCancelOrRefuse,
    OrderViewActionsSpecPaid: () => import('@/views/order/OrderViewActionsSpecPaid.vue'),
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('quote', ['quoteSelected', 'isQuoteOwner']),
  },

  methods: {
    enableCancel() {
      return this.quoteSelected.quote.paymentTerm === 'on_contract'
          || (
            this.quoteSelected.paymentIntent
            && this.quoteSelected.paymentIntent.status !== 'succeeded'
            && this.quoteSelected.paymentIntent.status !== 'processing'
          )
    },
    enableRecallCart() {
      return (
          this.quoteSelected.quote.paymentTerm === 'on_contract'
          || (
            this.quoteSelected.paymentIntent
              && this.quoteSelected.paymentIntent.status !== 'succeeded'
              && this.quoteSelected.paymentIntent.status !== 'processing'
          )
        )
        && !this.quoteSelected.quote.lines.filter(line => line.lineClass === 'FuelQuoteLine' || line.lineClass === 'HelicopterVariantQuoteLine').length
    },
    redirectToCatalog() {
      // Si la quote contient des lignes de type FuelQuoteLine ou HelicopterVariantQuoteLine, on redirige vers la page catalogue du vendeur hélico
      if (this.quoteSelected.quote.lines.filter(line => line.lineClass === 'HelicopterVariantQuoteLine').length) {
        this.$router.push({
          name: 'provider-details-helicopter',
          params: {
            provider_id: this.quoteSelected.quote.organization.id,
            trip_id: this.quoteSelected.checkout.tripId,
            step_id: this.quoteSelected.checkout.stepId,
            event_id: this.quoteSelected.checkout.stepEventId,
          }
        })
        return
      }

      this.$router.push({
        name: 'provider-details',
        params: {
          provider_id: this.quoteSelected.quote.organization.id,
          trip_id: this.quoteSelected.checkout.tripId,
          step_id: this.quoteSelected.checkout.stepId,
          event_id: this.quoteSelected.checkout.stepEventId,
          airport_id: this.quoteSelected.checkout.airportId,
        },
        query: {
          currentNav: 'description'
        },
      })
    }
  },
}
</script>
